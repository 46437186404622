#button {
  display: inline-block;
  background-color: #ff9800;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#button::after {
  content: "\f077";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}
#button:hover {
  cursor: pointer;
  background-color: #333;
}
#button:active {
  background-color: #555;
}
#button.show {
  opacity: 1;
  visibility: visible;
}

body {
  margin-top: 70px;
}
ul li a:link {
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
.services-box {
  border-radius: 10px;
}
body::-webkit-scrollbar {
  width: 9px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(238, 234, 234);
}

body::-webkit-scrollbar-thumb {
  background-color: #21333e;
  outline: 1px solid rgb(191, 193, 194);
  border-radius: 1px;
}
.navbar-bg {
  background-color: #21333e;
  color: #f9f9f9;
}

.white {
  color: #f9f9f9;
}
.active {
  color: #d4dce4;
}
.m-30 {
  margin: 30px;
}
.navbar a {
  color: white;
  font-weight: 600;
}
h4 {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  font-weight: 700;
  margin-bottom: 12px;
}
.faq_par {
  color: rgb(248, 246, 237);
}
/* 
* custom nav
*/

.custom__navbar {
  min-height: 76px;
  padding: 0;
}
.custom__collapse {
  align-self: stretch !important;
}
.custom__collapse .navbar-nav {
  align-self: stretch;
  align-items: center;
}
.custom__collapse .navbar-nav .nav-item {
  align-self: stretch;
  align-items: center;
  display: flex;
}
.custom__collapse .navbar-nav .nav-item .nav-link {
  align-self: stretch;
  align-items: center;
  display: flex;
}
/* ivi mobile ray bro */
/* padding-right: 15px;
    padding-left: 15px; */

/* ----------------nav bar dropdown-------- */

@media screen and (max-width: 600px) {
  .custom__collapse .navbar-nav .nav-item {
    align-items: stretch;
    flex-direction: column;
  }
  .custom__collapse .navbar-nav .nav-item .nav-link {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
    background-color: rgb(204, 230, 238);
  }
  .navbar .nav-item:hover .nav-link {
    color: #b6ebeb;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0px;
  }
}

.dropdown-large {
  padding: 10px 5px;
}

@media all and (min-width: 992px) {
  .dropdown-large {
    min-width: 540px;
  }
}

.dropdown-item {
  font-size: 12px;
  color: rgb(76, 74, 71);
  font-family: "Poppins", sans-serif;
  line-height: 1.02;
  font-weight: 600;
  padding: 10px;
  padding-bottom: 12px;
  padding-top: 12px;
}
.dropdown-item:hover {
  color: #eea514;
}
#dropdown {
  font-size: 15px;
  line-height: 14px;
  font-weight: 700;
}

.logo-image {
  height: 90px;
  width: 140px;
}
.heddd {
  font-size: 2px;
  color: #009688;
}
.laa {
  left: auto;
  right: 0;
}

.ENTI {
  padding-left: 8px;
  font-family: "san's", sans-serif;
  color: #0d0d0e;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 5px;
  border-bottom: rgb(235, 176, 99) 1px solid;
  margin-bottom: 10px;
}

/*-----------------footer-----------*/
a:hover {
  color: #009688;
}
.list-unstyled {
  color: whitesmoke;
  line-height: 30px;
}
.list-unstyled:hover {
  color: #009688;
}

.video-center {
  display: block;
  margin: 0 auto;
}
.fixed-bg {
  min-height: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-bg:hover {
  color: #00a6a6;
}

/*-------------------------- results--------------*/
.what__we__Do {
  position: relative;
}

.what__we__do__video__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 2.7;
  filter: grayscale(1);
}
.wha__we__do__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.pre-title {
  width: fit-content;
  margin: auto;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #00a6a6;
}

.heading {
  width: fit-content;
  margin: 1.5rem 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 45px;
  font-weight: 800;
  color: black;
  line-height: 1.2;
}
#numbers {
  font-size: 2.5rem;
  color: #00a6a6;
  position: absolute;
  top: 20px;
  padding-left: 45px;
  left: 0;
  width: 100%;
  line-height: 40px;
  font-weight: 700;
}
#result {
  color: #f9f9f9;
  margin: 1.5rem 0;
  margin-top: 5rem;
  vertical-align: baseline;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;

  background-attachment: fixed;
}
/*------------------bgc for both backgrounds for results and services-----------------*/
.bgc {
  background-color: #104747;
  height: 100%;
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  position: relative;
  font-size: 100%;
  opacity: 0.1 #c0c3d0;
}
/*cards*/
#services {
  background-color: #111117;
}
#excellence {
  font-size: 3rem;
  font-weight: 800;
  margin: 1.5rem 0;
  margin-top: 1.5rem;
  margin-bottom: o.5rem;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
}
#see {
  position: relative;
  z-index: 2;
  color: white;
  background-color: #a0d5e200;
  border-color: #058283;
  padding: 10px 25px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-weight: 600;
  border-radius: 4px 4px 4px 4px;
}
#sees {
  position: relative;
  z-index: 2;
  color: white;
  background-color: #a0d5e200;
  border-color: #058283;
  padding: 10px 25px;
  padding-top: 10px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-weight: 600;
  border-radius: 4px 4px 4px 4px;
  background-color: #058283;
}
.view {
  color: #00a6a6;
}
#see:hover {
  background-color: #058283;
  color: white;
}
.card-user {
  color: #058283;
  position: absolute;
  right: 30px;
  top: 30px;
  right: 45px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  opacity: 0.5;
  vertical-align: baseline;
}

.card-symbol {
  font-size: 44px;
  box-sizing: border-box;
  color: #058283;
  width: 50px;
  height: 44px;
  top: 5px;
  padding-left: 10px;
}
.odd:not(.custom) .card:not(.no-hover) {
  background-color: rgba(255, 255, 255, 0.035);
}
.card {
  background-color: rgba(255, 255, 255, 0.035);
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 0.4s ease-out 0s;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}

.card-title {
  margin: 1.5rem 0;
  margin-top: 1.5rem;
  margin-right: 0px;
  margin-bottom: 1.5rem;
  margin-left: 0px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: rgb(239, 238, 245);
}

.text-content {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 0 10px;
  color: #c0c3d0;
  font-family: "Montserrat", sans-serif;
}
/* sliders -----------------home page main-------------*/

.dddd,
.slide2,
.slide3 {
  font-size: 4.5rem;
  font-weight: 900;
  line-height: 1.2;
  font-family: sans-serif;
  color: #ffffff;
  letter-spacing: -2px;
  word-spacing: -2px;
}
.dd {
  padding-bottom: 200px;
}
.parag {
  font-size: 1.2rem;
  color: #c0c3d0;
  font-weight: 400;
}

.slide-content {
  margin-left: 60%;
  padding-bottom: 200px;
}

.slide-content2 {
  margin-right: 65%;
  padding-bottom: 200px;
}

/*------------------- Enterpreneur ---------------------*/
.y {
  font-size: 30px;
  font-weight: 800;
}

p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  line-height: 1.7;
  letter-spacing: 0;
  color: #777;
  font-family: "Poppins", sans-serif;
}
.widget-title,
.widget > h4 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 1px;
  box-sizing: border-box;
}
h1 {
  margin: 1rem 0;
  margin-top: 1rem;
  margin-right: 0px;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1;
  font-family: "Arimo", sans-serif;
  opacity: 0.9;
}

.reg {
  margin: 1rem 0;
  margin-top: 1rem;
  margin-right: 0px;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1;
  font-family: "Poppins", sans-serif;
}
/*------------------------ toggle effect -------------------------*/
.panel-group .panel {
  border-radius: 2px;
  box-shadow: none;
  border-color: #292525;
  margin-bottom: 0%;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: #fafafa;
  border-color: #111010;
  margin-bottom: 10px;
}
.seccccc {
  color: #000;
}
.panel-title {
  font-size: 14px;
  background-color: #eeeae2;
  line-height: 14px;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}
.panel-body {
  padding-top: 5px;
  padding-bottom: 15px;
}
.more-less {
  float: right;
  color: #212121;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #eeeeee;
}

/*------------- card hover-------------*/

.card:hover {
  transform: translatey(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.faqs {
  background: #121729;
  color: white;
}

/*-------------------- Scrollbar styles -------------------------*/

.myBox {
  background-color: rgba(red, rgb(102, 151, 102), rgb(74, 74, 172), alpha);
  height: 300px;
  margin-right: 20px;
  overflow-y: scroll; /* has to be scroll, not auto */
  float: left;
}

.touch {
  -webkit-overflow-scrolling: touch;
}
/* child scrollbar */
.shadow::-webkit-scrollbar {
  width: 8px;
}

.shadow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(248, 247, 247);
  border-radius: 10px;
}

.shadow::-webkit-scrollbar-thumb {
  background-color: rgb(231, 110, 12);
  /* outline: 1px solid rgb(234, 238, 237); */
  border-radius: 10px;
}
/* -----------------------------------team of experts home page-----------------------------*/

.pre-title3 {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #00a6a6;
}
h2 {
  margin: 1.5rem 0;
  margin-top: 1.5rem;
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.3;
  color: #21333e;
  font-family: "Poppins", sans-serif;
  opacity: 0.9;
}

.bgd {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #bad8d8;
  color: #00a6a6;
  position: relative;
}
.text-left {
  text-align: left;
  align-self: center;
}
.col-8 {
  flex: 0 0 66.666667%;
  flex-basis: 66.6667%;
  max-width: 100%;
}
#names {
  margin: 0 0 10px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  height: 1.2;
}

.person {
  color: #606d75;
  width: 100%;
  border-radius: 5px;
  margin-left: 10px;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.addres {
  margin-left: 50px;
}
.quote-content {
  width: 100%;
  height: auto;
  display: auto;
  align-items: center;
  justify-content: center;
  padding: 35px;
  background-color: #058283;
  border-radius: 4px;
}
#cont {
  color: #f1f1f1;
  opacity: 0.85;
  white-space: pre-wrap;
  margin: 1rem 0;
  line-height: 1.5;
  font-weight: 400;
  font-size: 1rem;
}
.quotes {
  position: absolute;
  right: 30px;
  top: 85%;
  right: 45px;
  width: 24px;
  height: 24px;
  font-size: 24px;
}
#A {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 1.5;
  font-family: Georgia, serif;
  padding-top: 10px;
}
#B {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 1.5;
}

/* ----------------------------------GET IN TOUCH---------------------*/
.contactss {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23064e77'/%3E%3Cstop offset='1' stop-color='%230a7dbe'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%238f8f8f'/%3E%3Cstop offset='0.09' stop-color='%23b3b3b3'/%3E%3Cstop offset='0.18' stop-color='%23c9c9c9'/%3E%3Cstop offset='0.31' stop-color='%23dbdbdb'/%3E%3Cstop offset='0.44' stop-color='%23e8e8e8'/%3E%3Cstop offset='0.59' stop-color='%23f2f2f2'/%3E%3Cstop offset='0.75' stop-color='%23fafafa'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='.5' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top left;
}
#touch {
  background-color: #00000013;
  width: 100%;
  height: 50px;
  padding: 1 1 1 1rem;
  border-radius: 4px;
  height: 1.2;
}
#getin {
  background-color: #00000013;
  padding: 0.5 0.5 0.5 0.5rem;
  flex: 0 0 100%;
  height: 130px;
}
#address {
  size: 30px;
  font-weight: 500;
  color: #058283;
}

/*----------------------Registrations---------------- */

.jumbotron {
  color: white;
  background-image: url(../../assets/images/GSt.png);
  background-position: top;
  padding-top: 20%;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 4.5rem;
  text-align: center;
  background-attachment: fixed;
}
.jumbotron2 {
  color: white;
  background-image: url(../../assets/images/ESI-2.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  color: rgb(81, 116, 52);
  text-align: center;
  background-attachment: fixed;
}
.jumbotron3 {
  color: white;
  background-image: url(../../assets/images/Rera-1.jpg);
  padding-top: 7%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron4 {
  color: white;
  background-image: url(../../assets/images/firm-1.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}

.jumbotron5 {
  color: white;
  background-image: url(../../assets/images/JMB.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron6 {
  color: white;
  background-image: url(../../assets/images/JMB-1.png);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron7 {
  color: white;
  background-image: url(../../assets/images/JMB-2.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron8 {
  color: white;
  background-image: url(../../assets/images/JMB-3.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron9 {
  color: white;
  background-image: url(../../assets/images/JMB-3.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50px;
  background-attachment: fixed;
}
.jumbotron10 {
  color: white;
  background-image: url(../../assets/images/DMHO-1.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  background-attachment: fixed;
}
.jumbotron11 {
  color: white;
  background-image: url(../../assets/images/DRUG-1.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  background-attachment: fixed;
}
.jumbotron12 {
  color: white;
  background-image: url(../../assets/images/FSSAI.png);
  padding-top: 20%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  background-attachment: fixed;
}
.jumbotron13 {
  color: white;
  background-image: url(../../assets/images/PCB-1.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  background-attachment: fixed;
}
.jumbotron14 {
  color: white;
  background-image: url(../../assets/images/BMW-1.jpg);
  padding-top: 10%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
  background-attachment: fixed;
}

.subheadingtitle {
  font-size: 16px;
  height: 30px;
  font-weight: bold;
  text-align: left;
  color: #058283;
}
.Documents-section h1::after {
  content: "";
  background: #ffd712;
  width: 45px;
  height: 4px;
  display: block;
  margin: 10px auto 25px;
}

#proofs {
  text-align: left;
  padding-left: 0;
}
.subheadingtitles {
  font-size: 16px;
  height: 30px;
  font-weight: bold;
  text-align: center;
  color: #058283;
  padding-right: 0px;
}

.title {
  color: #3d4141;
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

/*---Social icons------------------------------------------------*/
#icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  display: inline-block;
  background: #05828326;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #058283;
}

ul a {
  color: white;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}

.add {
  color: #058283;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 5px 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.add:hover {
  color: #058283;
}

.vl {
  border-right: 1px solid rgb(159, 165, 159);
  padding-right: 10px;
  height: relative;
}

.single-cat {
  border: #00a6a6;
  border-style: dotted;
  padding: 30px;
}

#vision {
  background-color: #058283;
}

.nclt {
  font-weight: 600;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}
.nclt:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  bottom: 0;
  left: 40%;
  border-bottom: 3px solid red;
}
#subscribe {
  background-color: #111117;
}
#inputs {
  background-color: #ebdbdb0d;
  color: #f9f9f9;
  width: 100%;
  height: 50px;
  background: no-repeat;
  border-color: #1b1b24;
  padding: 1rem;
  border-radius: 4px;
}

/*--------------sliders news------------------------------------------*/
#slide {
  background-color: #f1f6ff;
}
.post-slide {
  background: #fff;
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #bbcbd8;
}
.read-more {
  padding: 7px 20px;
  float: right;
  font-size: 12px;
  background: #2196f3;
  color: #ffffff;
  box-shadow: 0px 10px 20px -10px #1376c5;
  border-radius: 25px;
  text-transform: uppercase;
}
.read-more:hover {
  background: #3498db;
  text-decoration: none;
  color: #fff;
}
.post-date {
  color: #a9a9a9;
  font-size: 14px;
}
.post-date i {
  font-size: 20px;
  margin-right: 8px;
  color: #cfdace;
}

.card-img-top {
  border-radius: 4px;
}
:hover .card-img-top {
  opacity: 1;
  text-decoration: none;
}

.fa-chevron-left {
  position: absolute;
  top: -3%;
  left: 15px;
  padding: 0 18px 0 15px;
  border-radius: 50px;
  box-shadow: 3px 14px 25px -10px #92b4d0;
}

.fa-chevron-right {
  top: -3%;
  position: absolute;
  right: 15px;
  padding: 0 25px 0 18px;
  border-radius: 50px;
  box-shadow: -3px 14px 25px -10px #92b4d0;
}

.carousel-indicators {
  top: 103%;
  position: absolute;
  color: #00000013;
}

.flaticon {
  border-radius: 50%;
  border: oldlace;
}

/*-----------------------legal and advisory------------------*/
.side-bar-list p.side-bar-title {
  padding: 10px;
  background-color: #058283;
  color: #fff;
  line-height: 22px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.side-bar-title::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 10px solid transparent;
  position: absolute;
  left: 7px;
  bottom: -20px;
  border-top: 10px solid #058283;
}
hr.decor {
  line-height: 25px;
  background-color: rgb(248, 12, 12);
  width: 50px;
  border: 1px solid red;
  margin: 10px 0px 15px;
  padding-left: 0px;
}
.whatis {
  background-color: #f9f9f9;
  padding: 5px 20px;
  border-left: 3px solid #ec6502;
  color: #009688;
  font-size: 25px;
  font-weight: 600;
}
/*------branches-------*/
.branch {
  border-bottom: 2px solid #c988c0;
  padding-bottom: 5px;
  color: rgb(225, 122, 20);
  width: 60px;
}

/*---------------------advisory ---------------- */
.advsry {
  padding-top: 100px;
  font-size: 2.5rem;
  color: brown;
  font-family: bold;
  border-bottom: 3px solid #730096;
  padding-bottom: 3px;
  width: 60px;
}
.services-main-title {
  color: black;
  font-size: 1.3rem;
  font-weight: 700;
}
.fa-star-o {
  color: #009688;
  margin-right: 5px;
  font-size: 15px;
}
.advs {
  height: 50px;
  width: 100px;
  padding-left: 30px;
}
.style-7 {
  border: 3px solid #000;
  color: #009688;
  font: 20px Montserrat;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 600;
  max-width: 250px;
  display: block;
  text-align: center;
  margin: 30px auto 10px auto;
  position: relative;
  -webkit-box-shadow: -10px -10px 0 0 #d66d75, 10px 10px 0 0 #e29587;
  box-shadow: -10px -10px 0 0 #d66d75, 10px 10px 0 0 #e29587;
}
.style-7:hover {
  background: #000;
  border-color: #000;
  color: #fff;
  -webkit-box-shadow: -10px -10px 0 0 #d66d75, 10px 10px 0 0 #e29587;
  box-shadow: 10px -10px 0 0 #d66d75, -10px 10px 0 0 #e29587;
}

.fa-arrow-circle-right {
  color: #df5661;
  padding-top: 10px;
  margin-right: 10px;
}

/*--------------secure-------------------------*/
.secu {
  padding-top: 2px;
  font-size: 1.8rem;
  color: white;
  padding-bottom: 3px;
  border-bottom: 2px solid;
  border-color: rgb(250, 243, 248);
  border-width: 2px;
  font-family: "Poppins", sans-serif;
  width: auto;
}
.item1 {
  background-image: url(../images/card.png);
}
.item2,
.item3,
.item4,
.item5,
.item6 {
  background-image: url(../images//card2.png);
}
/*.item3 {
  background: linear-gradient(-45deg, #24ff72, #9a4eff);
}
.item4 {
  background: linear-gradient(-45deg, #64b5f6, #f403d1);
}
.item5 {
  background: linear-gradient(-45deg, #ffec61, #f321d7);
}
.item6 {
  background: linear-gradient(-45deg, #9a4eff, #24ff72);
} */
#about {
  background-image: url(../images/bgg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.cardssec,
#cards {
  /* background-image: url("../images/bacg.jpeg"); */
  background-image: radial-gradient(
      circle at 13% 47%,
      rgba(101, 97, 97, 0.1) 0%,
      rgba(101, 97, 97, 0.1) 25%,
      transparent 25%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 63%,
      rgba(143, 143, 143, 0.11) 0%,
      rgba(143, 143, 143, 0.11) 16%,
      transparent 16%,
      transparent 100%
    ),
    radial-gradient(
      circle at 81% 56%,
      rgba(65, 65, 65, 0.08) 0%,
      rgba(65, 65, 65, 0.08) 12%,
      transparent 12%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 48%,
      rgba(49, 48, 48, 0.06) 0%,
      rgba(49, 48, 48, 0.06) 6%,
      transparent 6%,
      transparent 100%
    ),
    radial-gradient(
      circle at 97% 17%,
      rgba(27, 26, 26, 0.08) 0%,
      rgba(27, 26, 26, 0.08) 56%,
      transparent 56%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 100%,
      rgba(23, 21, 21, 0.07) 0%,
      rgba(23, 21, 21, 0.07) 36%,
      transparent 36%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 52%,
      rgba(25, 24, 24, 0.08) 0%,
      rgba(25, 24, 24, 0.08) 6%,
      transparent 6%,
      transparent 100%
    ),
    linear-gradient(90deg, rgba(241, 131, 21, 0.82), rgba(241, 131, 21, 0.82));
}
/*{ background: linear-gradient(-100deg, #d49b72, #d86784 41.07%, #122a2c 76.05%);
border-radius: 10px;}
/*enterprenuer cards----------------------*/

.esipf {
  background-color: #16697a;
}

#documents {
  background-image: url(../../assets/images/Check.png);
  background-size: cover;
  background-attachment: fixed;
}
.chek {
  color: #160563;
}
.fa-check-square-o {
  color: #ec5e0c;
  margin-top: 10px;
}
.Quick {
  color: #12083b;
  line-height: 1.5;
  border-bottom: rgb(129, 112, 14) 1px solid;
  opacity: 0.9;
}
.itm {
  border: 2px solid #55b994;
}

.services-box {
  background-color: rgb(30, 36, 34);
}
.services-box:hover {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation-name: example;
  animation-duration: 0.25s;
  border-left: 8px solid rgb(8, 81, 94);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
#Industries h1 {
  color: rgb(37, 26, 14);
  font-size: 30px;
}
@keyframes example {
  0% {
    border-left: 2px solid #ffffff;
  }
  25% {
    border-left: 3px solid #ffe6e6;
  }
  50% {
    border-left: 4px solid #ff8080;
  }
  100% {
    border-left: 5px solid #ff0000;
  }
}

/* ----------------------------------------process ---------------------------------*/
.main-timeline {
  font-family: "Poppins", sans-serif;
}
.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}
.main-timeline .timeline {
  width: 60%;
  padding: 20px 0 0;
  margin: 0 0 20px 5px;
  float: right;
}
.main-timeline .timeline-content {
  color: #000;
  min-height: 125px;
  padding: 0 20px 20px 85px;
  display: block;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline-content:hover {
  text-decoration: none;
}
.main-timeline .timeline-icon {
  color: #fff;
  font-size: 40px;
  text-align: center;
  line-height: 100px;
  height: 100px;
  width: 100px;
  box-shadow: 0 0 5px #d1d1d1 inset, 0 0 5px #d1d1d1;
  border-radius: 10px;
  transform: rotate(45deg);
  position: absolute;
  left: -50px;
  top: 0;
}
.main-timeline .timeline-icon i {
  transform: rotate(-45deg);
}
.main-timeline .timeline-icon:before {
  content: "";
  background-color: #16bcb0;
  height: 80%;
  width: 80%;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
}
.main-timeline .title {
  color: #16bcb0;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}
.main-timeline .description {
  color: #777;
  font-size: 13px;
  letter-spacing: 1px;
  margin: 0;
}
.main-timeline .timeline:nth-child(even) {
  margin: 0 5px 20px 0;
  float: left;
}
.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 0 85px 20px 20px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: -50px;
}
.main-timeline .timeline:nth-child(4n + 2) .timeline-icon:before {
  background-color: #fdaa20;
}
.main-timeline .timeline:nth-child(4n + 2) .title {
  color: #fdaa20;
}
.main-timeline .timeline:nth-child(4n + 3) .timeline-icon:before {
  background-color: #994577;
}
.main-timeline .timeline:nth-child(4n + 3) .title {
  color: #994577;
}
.main-timeline .timeline:nth-child(4n + 4) .timeline-icon:before {
  background-color: #f2544b;
}
.main-timeline .timeline:nth-child(4n + 4) .title {
  color: #f2544b;
}
@media screen and (max-width: 767px) {
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    margin: 0 0 35px 0;
    padding: 100px 0 0;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    min-height: auto;
    padding: 0;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    font-size: 30px;
    line-height: 70px;
    height: 70px;
    width: 70px;
    left: 10px;
    top: -90px;
  }
}
/* .ccle {
  border-radius: 50%;
  border: mediumspringgreen 15px solid;
  width: 180px;
  height: 180px;
  background-color: #2e135a;
  align-items: center;
  text-align: center;
  color: #fff;
  box-shadow: inset 0px 0px 0px 10px #fff;
}

.inn{
  font-size: 26px;
  font-weight: 600;
} */

/* -------------mobile responsive-------------  */
@media only screen and (max-width: 991px) {
  h2,
  #excellence,
  .heading {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  #single {
    width: 90%;
  }
}

@media only screen and (max-device-width: 480px) {
  #team {
    width: 95%;
  }
}
@media only screen and (max-device-width: 480px) {
  .body {
    width: 100%;
  }
}
@media only screen and (max-device-width: 700px) {
  .secu {
    font-size: 25px;
    align-items: center;
    line-height: 1.9rem;
  }
}
@media only screen and (max-device-width: 480px) {
  .title {
    font-size: 20px;
  }
  .pre-title {
    font-size: 10px;
  }
}
@media only screen and (max-device-width: 700px) {
  h1 {
    font-size: 19px;
    line-height: 1.5rem;
    margin-top: 10px;
    color: #2a2a2a;
    font-weight: 700;
  }
  .title {
    font-size: 18px;
    margin: 10px;
  }
  .text {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-device-width: 1000px) {
  .vidsection {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .btn {
    padding: 2px 4px;
    line-height: 1;
    border-radius: 3px;
  }
}
/* @media (max-width: 500px) {
  .dd,
  .dddd,
  .Carousel.Caption {
    padding: 1px 1px;
    font-size: 2%;
    line-height: 0;
    width: 100%;
    margin-top: 5px;
  }
} */
/* -----------------------------testimonial section------------------ */

.tst {
  text-align: center;
  color: #454343;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  margin-bottom: 70px;
  text-transform: uppercase;
  z-index: 999;
  align-items: center;
}
@media only screen and (max-device-width: 700px) {
  .tst {
    font-size: 15px;
    align-items: center;
  }
}
.white-heading {
  color: #ffffff;
}
.tst:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  transform: translateX(-50%);

  background-repeat: no-repeat;
  background-position: center;
}
.white-heading:after {
  background: url(../../assets/images/tst.png);
  background-repeat: no-repeat;
  background-position: center;
}

.heading span {
  font-size: 18px;
  display: block;
  font-weight: 500;
}
.white-heading span {
  color: #ffffff;
  align-self: center;
}
/*-----Testimonial-------*/

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";

  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  height: 100%;
  position: relative;
  background: url(../../assets/images/Test.png);

  background-position: center;
  background-size: cover;
}
#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}
.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 540px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 5px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
  color: #ffffff;
  font-size: 15px;
  line-height: 1.4;
  margin: 20px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 20px;
}

.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 10px;
  height: 10px;
}
/* ------testimonial  close-------*/

.fa-arrow-right:hover {
  color: white;
}
